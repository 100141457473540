import React from "react";
import { useState } from "react";
import Select from 'react-select';
import Add from "@material-ui/icons/Add";
import Remove from "@material-ui/icons/Remove";

function OrderCell(props){

    const [qty, setQty] = useState(props.quantity);
    const [optionSelected, setOptionSelected] = useState(props.misura)

    const options = getMisureOptions();
    
    const articolo = props.articolo;

    function getMisureOptions() {
        var customOptions = [{
            value:"0",
            label:"Seleziona..."
        }];
        props.misure.forEach(elem => {
            customOptions.push({
                value: elem.Udm,
                label: elem.Descrizione
            })
        });
        return customOptions;
    }

    function removeQty(){
        if(qty > 0){
            let tmpQty = qty-1;
            setQty(tmpQty);
            props.setCarrello(articolo, tmpQty, optionSelected);
        }
    }

    function addQty(){
        let tmpQty = qty+1;
        setQty(tmpQty);
        props.setCarrello(articolo, tmpQty, optionSelected);
    }

    

    const handleChange = event => {
        if(event && event.label){
            console.log(event.label);
            setOptionSelected({
                value:event.value,
                label:event.label
            })
            props.setCarrello(articolo, qty, {
                value:event.value,
                label:event.label
            });
        } 
        
    };

    return (
        <div>
            <div className="row"
                style={{
                    padding: "0px 24px",
                }}
            >
                <div 
                    className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12"
                    style={{
                        marginTop:"12px",
                        textAlign:"left"
                    }}
                >
                    <p
                        style={{
                            fontFamily: "SemiBold",
                            fontSize:"18px",
                            color: "#1C1C1A",
                            display: "inline-block",
                            width: "100%",
                            textAlign: "left !important",
                            marginBottom: "0px"
                        }}
                    >
                        
                        {articolo.DescrizioneArticolo}
                    </p>
                    <p
                        style={{
                            fontFamily: "Medium",
                            fontSize:"14px",
                            color: "#808080",
                            display: "inline-block",
                            width: "100%",
                            textAlign: "left !important",
                            marginBottom: "0px"
                        }}
                    >
                        # {articolo.CodArticolo}
                    </p>
                </div>
                <div className="row">
                    <div 
                        className="col-lg-7 col-md-7 col-sm-7 col-xs-7 col-7"
                        style={{
                            marginTop:"8px",
                            textAlign:"left"
                        }}
                    >
                        <Select 
                            defaultValue={optionSelected}
                            options={options} 
                            isSearchable={false}
                            placeholder="Seleziona..."
                            onChange={handleChange}
                            isClearable={false}
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    width:"100%",
                                    height: "44px",
                                    padding: "0px 4px",
                                    borderRadius: "12px",
                                    border: "2px solid #D9D9D9",
                                    backgroundColor: "#FFFFFF",
                                    
                                    fontFamily: "Regular",
                                    fontSize: "14px",
                                })
                            }}
                            
                        />
                    </div>



                    <div 
                        className="col-lg-5 col-md-5 col-sm-5 col-xs-5 col-5"
                        style={{
                            marginTop:"8px",
                            textAlign:"left",
                            
                        }}
                    >
                        <div 
                            
                            style={{
                                display: "flex",
                                flexDirection: "row",
                            }}
                        >
                            <button 
                                onClick={removeQty}
                                style={{
                                    backgroundColor: "transparent",
                                    border: "none",
                                    
                                }}
                            >
                                <Remove 
                                    style={{
                                        width: "32px",
                                        height: "32px",
                                        color: "black" 
                                    }}
                                />
                            </button>
                            <div
                                style={{
                                    border: "2px solid #D9D9D9",
                                    borderRadius: "12px",
                                    height: "44px",
                                    width: "44px",
                                    display:"flex",
                                    justifyContent:"center",
                                    alignItems:"center"
                                }}
                            >
                                <label
                                    style={{
                                        fontFamily: "Regular",
                                        fontSize:"16px",
                                        color: "#808080",
                                        textAlign: "center",
                                        width: "44px"
                                    }}
                                >
                                    {qty}
                                </label>

                            </div>
                            <button 
                                onClick={addQty}
                                style={{
                                    backgroundColor: "transparent",
                                    border: "none",
                                    
                                }}
                            >
                                <Add 
                                    style={{
                                        width: "32px",
                                        height: "32px",
                                        color: "black" 
                                    }}
                                />
                            </button>
                            
                        </div>
                    </div>








                    {/* <div 
                        className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-5"
                        style={{
                            marginTop:"8px",
                            textAlign:"left"
                        }}
                    >
                        <div 
                            className="row"
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                        >
                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-4"
                                style={{
                                    textAlign:"left",
                                }}
                            >
                                <button 
                                    onClick={removeQty}
                                    style={{
                                        backgroundColor: "transparent",
                                        border: "none"
                                    }}
                                >
                                    <Remove 
                                        style={{
                                            width: "32px",
                                            height: "32px",
                                            color: "black" 
                                        }}
                                    />
                                </button>
                            </div>
                            <div
                                className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-3"
                                style={{
                                    textAlign:"center"
                                }}
                            >
                                <div
                                    style={{
                                        border: "2px solid #D9D9D9",
                                        borderRadius: "12px",
                                        height: "44px",
                                        width: "44px",
                                        display:"flex",
                                        justifyContent:"center",
                                        alignItems:"center"
                                    }}
                                >
                                    <label
                                        style={{
                                            fontFamily: "Regular",
                                            fontSize:"16px",
                                            color: "#808080",
                                            
                                        }}
                                    >
                                        {qty}
                                    </label>

                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-4"
                                style={{
                                    textAlign:"right"
                                }}
                            >
                                <button 
                                onClick={addQty}
                                    style={{
                                        backgroundColor: "transparent",
                                        border: "none"
                                    }}
                                >
                                    <Add 
                                        style={{
                                            width: "32px",
                                            height: "32px",
                                            color: "black" 
                                        }}
                                    />
                                </button>
                            </div>
                        </div>
                        
                    </div> */}
                </div>
                
            </div>
            <div 
                style={{
                    backgroundColor: "#F0F0F0",
                    height: "2px",
                    marginTop: "16px"
                }}
            />
        </div>
       
    )
}

export default OrderCell;
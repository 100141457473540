import React from "react";
import { useState, useEffect } from "react";
import { LogoTop } from "..";
import  ArrowBack from "@material-ui/icons/ArrowBack";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CarrelloCell } from "../components/CarrelloCell";
import CarrelloModal from "../components/CarrelloModal";
import axios from "axios";
import { SERVICE_URL } from "..";
import DatePicker from "react-datepicker";
import * as moment from "moment";

import "react-datepicker/dist/react-datepicker.css";

import { registerLocale, setDefaultLocale } from  "react-datepicker";
import it from 'date-fns/locale/it';

registerLocale('it', it);
setDefaultLocale("it");

export function ConfirmOrderView() {

    const { state } = useLocation();
    const navigate = useNavigate();

    const [carrello, setCarrello] = useState(state.carrello);
    const [articoloSelezionato, setArticoloSelezionato] = useState();
    const [open, setOpen] = useState(false);
    const [note, setNote] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const {token} = useParams();

    const [dataConsegna, setDataConsegna] = useState();
    const [dataToSend, setDataToSend] = useState("");

    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    function handleDateSelect(date){
        setDataConsegna(date);
        setDataToSend(moment(date).format("yyyy-MM-DD"));

    }

    function handleDateChange(date){
        setDataConsegna(date);
        setDataToSend(moment(date).format("yyyy-MM-DD"));
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleOpen = (articolo) => {
        setArticoloSelezionato(articolo);
        setOpen(true);
    }

    const handleTextNote = event => {

        if(event && event.target && event.target.value){
            setNote(event.target.value);
        }
    }

    function goBack(){

        navigate("/" + token, {
            replace: true,
            state: {
                carrello: carrello
            }
        });
    }

    function eliminaArticolo(){
        let tmpArticles = [...carrello];
        var indexToDelete = -1;
        tmpArticles.forEach((elem,index) => {
            if(elem.CodArticolo === articoloSelezionato.CodArticolo ){
                indexToDelete = index;
            }
        });
        tmpArticles.splice(indexToDelete, 1);
        setCarrello(tmpArticles);
        setOpen(false);
        if(tmpArticles.length === 0){
            window.history.back();
        }
    }

    function ordina(){

        let products = [];
        carrello.forEach((elem,index) => {
            products.push({
                "CodArticolo" : elem.CodArticolo,
                "UnitaMisura" : elem.misura.value,
                "Quantita" : parseInt(elem.qty),
                "DescrizioneArticolo": elem.DescrizioneArticolo,
                "UnitaMisuraLabel": elem.misura?.label||""

            })
        });

        let body = {
            "DataConsegnaRichiesta" : dataToSend && dataToSend !== "Invalid date" ? dataToSend : "",
            "Note" : note,
            "Dettaglio": products
        }

        setIsLoading(true);

        axios.post(SERVICE_URL + '/order', body, {
            headers: {
                "Access-Control-Allow-Origin" : "*",
                "Access-Control-Allow-Methods": "POST, GET, PUT",
                "Access-Control-Allow-Headers" : "Content-type",
                "Content-type": "Application/json",
                'Authorization' : `Bearer ${token}`
            }
        }).then((response) => {
            if(response.data){

                setIsLoading(false);
                navigate("/" + token + "/orderSuccess");
            }
        }).catch((error) => {
            console.error(error);
            setIsLoading(false);

        })

    }

    function sleep (time) {
        return new Promise((resolve) => setTimeout(resolve, time));
    }

    return (
        <div
        className="container"
            style={{
                marginTop: "10px",
                position: "relative",
                padding:"0px"
            }}
        >
            <div
                style={{
                    display:`${isLoading ? "flex" : "none"}`,
                    justifyContent:"center",
                    alignItems:"center",
                    height:"100%",
                    width: "100%",
                    position: "absolute",
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    zIndex: "100"
                }}
                >
                <dotlottie-player src="https://lottie.host/146b6344-4522-4fb6-801b-ad69e405c7da/SOi8Hfc8r6.json" mode="normal" background="transparent" speed="1" style={{width: "300px", height: "300px", display: "block"}} direction="1" loop autoplay></dotlottie-player>
            </div>

            <div
                className="row"
                style={{
                    padding: "0px 24px",
                }}
            >
                <div
                    className="col-lg-2 col-md-2 col-sm-2 col-xs-2 col-2"
                    onClick={goBack}
                    style={{
                        marginTop: "16px"
                    }}
                >
                    <ArrowBack
                        style={{
                            width: "28px",
                            height: "28px",
                            color: "black"
                        }}
                    />
                </div>
                <div
                    className="col-lg-8 col-md-8 col-sm-8 col-xs-8 col-8"
                    style={{
                        marginTop: "16px",
                        textAlign: "center"
                    }}
                >
                    <img
                        src={LogoTop}
                        alt=''
                        style={{
                            height: "60px",
                            display: "inline-block"
                        }}
                    />
                </div>
                <div
                    className="col-lg-2 col-md-2 col-sm-2 col-xs-2 col-2"
                    style={{
                        marginTop: "16px",

                    }}
                >


                </div>

            </div>
            <div
                className="row"
                style={{
                    marginTop: "32px",
                    textAlign:"center",
                    padding: "0px 24px",
                }}
            >
                <p
                    style={{
                        fontFamily: "SemiBold",
                        fontSize:"20px",
                        color: "#1C1C1A",
                        display: "inline-block",
                        width: "100%",
                        textAlign: "center !important",
                        marginBottom: "0px"
                    }}
                >
                    Conferma ordine
                </p>

            </div>
            <div
                className="row"
                style={{
                    marginTop: "12px",
                    textAlign: "center",
                    padding: "0px 24px",
                }}
            >
                <p
                    style={{
                        fontFamily: "Regular",
                        fontSize:"16px",
                        color: "#808080",
                        display: "inline-block",
                        width: "100%",
                        textAlign: "center !important",
                        marginBottom: "0px"
                    }}
                >
                    Conferma i prodotti selezionati e clicca per concludere l'ordine
                </p>

            </div>

            <div
                style={{
                    backgroundColor: "#F0F0F0",
                    height: "2px",
                    marginTop: "40px",
                    padding: "0px 24px",
                }}
            />


            {carrello.map((articolo) => (
                <CarrelloCell key={articolo.CodArticolo} articolo={articolo} showModal={handleOpen}/>
            ))}

            <div
                className="row"
                style={{
                    marginTop: "40px",
                    padding: "0px 24px",
                }}
            >
                <p
                    style={{
                        fontFamily: "SemiBold",
                        fontSize:"18px",
                        color: "#1C1C1A",
                        display: "inline-block",
                        width: "100%",
                        textAlign: "left !important",
                        marginBottom: "0px"
                    }}
                >

                    DATA RICHIESTA CONSEGNA
                </p>
                <DatePicker
                    selected={dataConsegna}
                    onSelect={handleDateSelect} //when day is clicked
                    onChange={handleDateChange} //only when value has changed
                    locale="it"
                    dateFormat="yyyy-MM-dd"
                    minDate={tomorrow}
                    className="myDatePicker"
                />
            </div>
            <div
                className="row"
                style={{
                    marginTop: "40px",
                    padding: "0px 24px",
                }}
            >
                <p
                    style={{
                        fontFamily: "Regular",
                        fontSize:"16px",
                        color: "#808080",
                        display: "inline-block",
                        width: "100%",
                        textAlign: "center !important",
                        marginBottom: "0px"
                    }}
                >
                    Vuoi lasciare delle note?
                </p>

            </div>
            <div
                className="row"
                style={{
                    marginTop: "12px",
                    padding: "0px 24px",
                }}
            >
                <textarea
                    placeholder="Scrivi qui..."
                    rows={4}
                    onChange={handleTextNote}
                    style={{
                        fontFamily: "Regular",
                        fontSize:"14px",
                        color: "#1C1C1A",
                        borderRadius: "12px",
                        border: "2px solid #F0F0F0",
                        padding: "12px"
                    }}
                />

            </div>
            <div
                style={{
                    backgroundColor: "white",
                    height: "100px"
                }}
            />


            <CarrelloModal isOpen={open} onClose={handleClose}>
                <>
                    <p
                        style={{
                            fontFamily: "Medium",
                            fontSize:"18px",
                            color: "#1C1C1A",
                            textAlign: "center !important",
                            marginBottom: "0px"
                        }}
                    >
                        Sei sicuro di voler eliminare questo prodotto dal tuo ordine?
                    </p>
                    <div
                        style={{
                            marginTop: "52px"
                        }}
                    >
                        <button
                            className="btn-black"
                            style={{
                                fontSize:"16px"
                            }}
                            onClick={eliminaArticolo}
                        >
                            Elimina prodotto
                        </button>
                    </div>
                    <div
                        onClick={handleClose}
                        style={{
                            marginTop:"24px"
                        }}
                    >
                        <p
                            style={{
                                fontFamily: "SemiBold",
                                textAlign: "center",
                                fontSize:"16px",
                                color: "#808080",
                                textDecoration: "underline"
                            }}
                        >
                            Annulla
                        </p>
                    </div>
                </>
            </CarrelloModal>

            <div
                className="footer"
                style={{
                    display:`${isLoading ? "none" : "block"}`,
                    position: "fixed",
                    bottom: 0,
                    left:0,
                    height: "75px",
                    borderRadius: "24px 24px 0px 0px",
                    borderTop: "2px solid #F0F0F0",
                    boxShadow: "0px -5px 25px 0px rgba(0, 0, 0, 0.10)",
                    width: "100%",
                    backgroundColor:"#FFFFFF"
                }}
            >
                <div
                    className="row"
                    style={{
                        marginTop: "12px",
                        padding: "0px 24px"
                    }}
                >
                    <div
                        className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12"
                        style={{
                            textAlign: "center"
                        }}
                    >
                        <button
                            className="btn-green"
                            onClick={ordina}
                        >
                            Conferma ordine
                        </button>
                    </div>
                </div>
            </div>

        </div>
    )
}

import React from "react";
import { useState, useEffect } from "react";
import  ArrowBack from "@material-ui/icons/ArrowBack";
import Announcement from "@material-ui/icons/Announcement"
import { 
    LogoTop, 
    AnatraRedIcon, AnatraWhiteIcon, AnatraBlackIcon,
    CapponeRedIcon, CapponeWhiteIcon, CapponeBlackIcon,
    ConiglioRedIcon, ConiglioWhiteIcon, ConiglioBlackIcon,
    FaraonaRedIcon, FaraonaWhiteIcon, FaraonaBlackIcon,
    NostraniRedIcon, NostraniWhiteIcon, NostraniBlackIcon,
    OvinoRedIcon, OvinoWhiteIcon, OvinoBlackIcon,
    PolloRedIcon, PolloWhiteIcon, PolloBlackIcon, 
    PolloGialloRedIcon, PolloGialloWhiteIcon, PolloGialloBlackIcon,
    QuagliaRedIcon, QuagliaWhiteIcon, QuagliaBlackIcon,
    SelvagginaRedIcon, SelvagginaWhiteIcon, SelvagginaBlackIcon,
    SuinoRedIcon, SuinoWhiteIcon, SuinoBlackIcon,
    TacchinoRedIcon, TacchinoWhiteIcon, TacchinoBlackIcon,
    TuttiRedIcon, TuttiWhiteIcon, TuttiBlackIcon,
    UovaRedIcon, UovaWhiteIcon, UovaBlackIcon,
    VarieRedIcon, VarieWhiteIcon, VarieBlackIcon
} from "..";
import OrderCell from "../components/OrderCell";
import CategoryCell from "../components/CategoryCell";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { useLocation, useParams } from "react-router-dom";
import { SERVICE_URL } from "..";


export function HomeView(props) {

    const [isSelected, setIsSelected] = useState("00");
    const [articoli, setArticoli] = useState(props.articoli);

    const { state } = useLocation();
    const [articoliSelezionati, setArticoliSelezionati] = useState(state && state.carrello ? state.carrello : []);

    const [isCatalogo, setIsCatalogo] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [articoliOriginali, setArticoliOriginali] = useState(props.articoli ? props.articoli : []);

    const {token} = useParams();
    
    //const SERVICE_PATH = "http://192.168.1.207:4000/api"

    
    let unitaMisure = props.unitaMisure;

    const navigate = useNavigate();
    
    function ordina(){
        if(articoliSelezionati.length > 0){
            navigate("./carrello", {
                replace: false,
                state: {
                    carrello: articoliSelezionati
                }
            });
        } 
    }

    function sendRequest(){
        navigate("./sendRequest");
    }

    function setAssortimento(){
        setIsLoading(true);
        axios.get(SERVICE_URL + '/start', {
            headers: {
                'Authorization' : 'Bearer ' + token
            }
        }).then((response) => {

            if(response.data){
                
                setArticoli(response.data.articoli);
                setArticoliOriginali(response.data.articoli);
                unitaMisure = response.data.unita_misura
                setIsSelected("00");
                setIsCatalogo(false);
                setIsLoading(false);
                
            }

        }).catch((error) => {
            console.error(error);
        });
        
    }

    function setCatalogo(){
        setIsLoading(true);
        axios.get(SERVICE_URL + '/catalogo', {
            headers: {
                'Authorization' : 'Bearer ' + token
            }
        }).then((response) => {

            if(response.data){
                
                setArticoli(response.data.articoli);
                setArticoliOriginali(response.data.articoli);
                unitaMisure = response.data.unita_misura
                setIsSelected("00");
                setIsCatalogo(true);
                setIsLoading(false);
                
            }

        }).catch((error) => {
            console.error(error);
        });
    }

    function changeCategory(category){
        let tmpArticles = [];

        articoliOriginali.forEach(elem => {
            if(elem.CodFamiglia === category || category === "00"){
                tmpArticles.push(elem);
            }
        });
        
        setArticoli(tmpArticles);
        setIsSelected(category);
    }

    function isEmpty(category, isCatalogo){
        if(isCatalogo){
            return true;
        } else {
            let tmpArticles = [];
            articoliOriginali.forEach(elem => {
                if(elem.CodFamiglia === category || category === "00"){
                    tmpArticles.push(elem);
                }
            });
            return tmpArticles.length > 0;
        }

    }

    function setCarrello(articolo, qty, misura){
        let tmpArticles = [...articoliSelezionati];
        if(qty === 0 || misura.value === "0"){
            tmpArticles = articoliSelezionati.filter((item) => item.CodArticolo !== articolo.CodArticolo);
            
        } else {
            let found = false;
            articoliSelezionati.forEach((elem,index) => {
                if(elem.CodArticolo === articolo.CodArticolo ){
                    tmpArticles[index].qty = qty;
                    tmpArticles[index].misura = misura
                    found = true;
                }
            });
            if(!found){
                if(qty > 0 && misura.value !== "0"){
                    let tmpArticle = articolo;
                    tmpArticle.qty = qty;
                    tmpArticle.misura = misura;
                    tmpArticles.push(tmpArticle)
                }
            }
            
        }
        setArticoliSelezionati(tmpArticles);
        console.log(tmpArticles);
    }

    function getArticleQuantity(currentArticle){

        var qty = 0;
        articoliSelezionati.forEach(elem => {
            if(elem.CodArticolo === currentArticle.CodArticolo ){
                qty = elem.qty;
            }
        });
        return qty;
    }

    function getArticleMisura(currentArticle){

        var misura = {
            value:"0",
            label:"Seleziona..."
        };
        articoliSelezionati.forEach(elem => {
            if(elem.CodArticolo === currentArticle.CodArticolo ){
                misura = elem.misura;
            }
        });
        return misura;
    }

    function goBack(){
        window.open("https://wa.me/393288813933?text=Menu",'_self');
    }
    

    return (
       
        <div 
        className="container"
            style={{
                marginTop: "10px",
                backgroundColor: "#FFFFFF",
                position: "relative"
            }}
        >
            
            <div
                className="row"
                style={{
                    padding: "0px 24px",
                }}
            >
                <div
                    onClick={goBack}
                    className="col-lg-2 col-md-2 col-sm-2 col-xs-2 col-2"
                    style={{
                        marginTop: "16px"
                    }}
                >
                    <ArrowBack 
                        style={{
                            width: "28px",
                            height: "28px",
                            color: "black" 
                        }}
                    />
                </div>
                <div
                    className="col-lg-8 col-md-8 col-sm-8 col-xs-8 col-8"
                    style={{
                        marginTop: "16px",
                        textAlign: "center"
                    }}
                >
                    <img
                        src={LogoTop}
                        alt=''
                        style={{
                            height: "60px",
                            display: "inline-block"
                        }}
                    />
                </div>
                <div
                    className="col-lg-2 col-md-2 col-sm-2 col-xs-2 col-2"
                    style={{
                        marginTop: "16px",
                        
                    }}
                >
                    <div 
                    onClick={sendRequest}
                        style={{
                            width: "40px",
                            height: "40px",
                            backgroundColor: "#F0F0F0",
                            borderRadius: "20px",
                            justifyContent:"center",
                            alignItems:"center",
                            display:"flex"
                        }}
                    >
                        <Announcement 
                            style={{
                                width: "24px",
                                height: "24px",
                                color: "#39A463",
                                
                            }}
                        />
                    </div>

                </div>

            </div>
            <div>
                <div
                    className="row"
                    style={{
                        marginTop: "28px",
                        padding: "0px 24px",
                    }}
                >
                    <p
                        style={{
                            fontFamily: "SemiBold",
                            fontSize:"20px",
                            color: "#1C1C1A",
                            display: "inline-block",
                            width: "100%",
                            textAlign: "center !important",
                            marginBottom: "0px"
                        }}
                    >
                        Nuovo ordine
                    </p>

                </div>
                <div
                    className="row"
                    style={{
                        marginTop: "12px",
                        padding: "0px 24px",
                    }}
                >
                    <p
                        style={{
                            fontFamily: "Regular",
                            fontSize:"16px",
                            color: "#808080",
                            display: "inline-block",
                            width: "100%",
                            textAlign: "center !important",
                            marginBottom: "0px"
                        }}
                    >
                        Seleziona le quantità di prodotti
                        che vuoi ordinare e clicca su Ordina
                    </p>

                </div>

                <div
                    className="row"
                    style={{
                        marginTop: "24px",
                        padding: "0px 24px",
                    }}
                >
                    <div
                        className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6"
                    >
                        <button
                            className={isCatalogo ? "btn-bordeaux-outline" : "btn-bordeaux"}
                            onClick={setAssortimento}
                        >
                            ASSORTIMENTO
                        </button>
                        
                    </div>
                    <div
                        className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6"
                    >
                        <button
                            className={isCatalogo ? "btn-black" : "btn-black-outline"}
                            onClick={setCatalogo}
                        >
                            CATALOGO
                        </button>
                        
                    </div>
                </div>
                <div 
                    className="row hide-scroll"
                    style={{
                        marginTop: "24px",
                        display: "flex",
                        flexWrap: "nowrap",
                        overflowX: "scroll",
                        padding: "0px 24px",
                        
                    }}
                >
                    {isEmpty("00", isCatalogo) &&
                        <CategoryCell setCategory={changeCategory} isSelected={isSelected === "00"} isCatalogo={isCatalogo} categoria="VEDI TUTTI" codCategory="00" image={isSelected === "00" ? TuttiWhiteIcon : (isCatalogo ? TuttiBlackIcon : TuttiRedIcon) }/>
                    }
                    {isEmpty("01", isCatalogo) &&
                        <CategoryCell setCategory={changeCategory} isSelected={isSelected === "01"} isCatalogo={isCatalogo} categoria="POLLO" codCategory="01" image={isSelected === "01" ? PolloWhiteIcon : (isCatalogo ? PolloBlackIcon : PolloRedIcon)}/>
                    }
                    {isEmpty("02", isCatalogo) &&
                        <CategoryCell setCategory={changeCategory} isSelected={isSelected === "02"} isCatalogo={isCatalogo} categoria="POLLO GIALLO" codCategory="02" image={isSelected === "02" ? PolloGialloWhiteIcon : (isCatalogo ? PolloGialloBlackIcon : PolloGialloRedIcon)}/>
                    }
                    {isEmpty("03", isCatalogo) &&
                        <CategoryCell setCategory={changeCategory} isSelected={isSelected === "03"} isCatalogo={isCatalogo} categoria="UOVA" codCategory="03" image={isSelected === "03" ? UovaWhiteIcon : (isCatalogo ? UovaBlackIcon : UovaRedIcon)}/>
                    }
                    {isEmpty("04", isCatalogo) &&
                        <CategoryCell setCategory={changeCategory} isSelected={isSelected === "04"} isCatalogo={isCatalogo} categoria="TACCHINO" codCategory="04" image={isSelected === "04" ? TacchinoWhiteIcon : (isCatalogo ? TacchinoBlackIcon : TacchinoRedIcon)}/>
                    }
                    {isEmpty("05", isCatalogo) &&
                        <CategoryCell setCategory={changeCategory} isSelected={isSelected === "05"} isCatalogo={isCatalogo} categoria="CONIGLIO" codCategory="05" image={isSelected === "05" ? ConiglioWhiteIcon : (isCatalogo ? ConiglioBlackIcon : ConiglioRedIcon)}/>
                    }
                    {isEmpty("06", isCatalogo) &&
                        <CategoryCell setCategory={changeCategory} isSelected={isSelected === "06"} isCatalogo={isCatalogo} categoria="FARAONA" codCategory="06" image={isSelected === "06" ? FaraonaWhiteIcon : (isCatalogo ? FaraonaBlackIcon : FaraonaRedIcon)}/>
                    }
                    {isEmpty("07", isCatalogo) &&
                        <CategoryCell setCategory={changeCategory} isSelected={isSelected === "07"} isCatalogo={isCatalogo} categoria="NOSTRANI" codCategory="07" image={isSelected === "07" ? NostraniWhiteIcon : (isCatalogo ? NostraniBlackIcon : NostraniRedIcon)}/>
                    }
                    {isEmpty("08", isCatalogo) &&
                        <CategoryCell setCategory={changeCategory} isSelected={isSelected === "08"} isCatalogo={isCatalogo} categoria="QUAGLIE" codCategory="08" image={isSelected === "08" ? QuagliaWhiteIcon : (isCatalogo ? QuagliaBlackIcon : QuagliaRedIcon)}/>
                    }
                    {isEmpty("09", isCatalogo) &&
                        <CategoryCell setCategory={changeCategory} isSelected={isSelected === "09"} isCatalogo={isCatalogo} categoria="ANATRA" codCategory="09" image={isSelected === "09" ? AnatraWhiteIcon : (isCatalogo ? AnatraBlackIcon : AnatraRedIcon)}/>
                    }
                    {isEmpty("10", isCatalogo) &&
                        <CategoryCell setCategory={changeCategory} isSelected={isSelected === "10"} isCatalogo={isCatalogo} categoria="SELVAGGINA" codCategory="10" image={isSelected === "10" ? SelvagginaWhiteIcon : (isCatalogo ? SelvagginaBlackIcon : SelvagginaRedIcon)}/>
                    }
                    {isEmpty("11", isCatalogo) &&
                        <CategoryCell setCategory={changeCategory} isSelected={isSelected === "11"} isCatalogo={isCatalogo} categoria="OVINO" codCategory="11" image={isSelected === "11" ? OvinoWhiteIcon : (isCatalogo ? OvinoBlackIcon : OvinoRedIcon)}/>
                    }
                    {isEmpty("12", isCatalogo) &&
                        <CategoryCell setCategory={changeCategory} isSelected={isSelected === "12"} isCatalogo={isCatalogo} categoria="SUINO" codCategory="12" image={isSelected === "12" ? SuinoWhiteIcon : (isCatalogo ? SuinoBlackIcon : SuinoRedIcon)}/>
                    }
                    {isEmpty("13", isCatalogo) &&
                        <CategoryCell setCategory={changeCategory} isSelected={isSelected === "13"} isCatalogo={isCatalogo} categoria="CAPPONE" codCategory="13" image={isSelected === "13" ? CapponeWhiteIcon : (isCatalogo ? CapponeBlackIcon : CapponeRedIcon)}/>
                    }
                    {isEmpty("99", isCatalogo) &&
                        <CategoryCell setCategory={changeCategory} isSelected={isSelected === "99"} isCatalogo={isCatalogo} categoria="VARIE" codCategory="99" image={isSelected === "99" ? VarieWhiteIcon : (isCatalogo ? VarieBlackIcon : VarieRedIcon)}/>
                    }
                </div>
            
                <div 
                    style={{
                        backgroundColor: "#F0F0F0",
                        height: "2px",
                        marginTop: "18px",
                        padding: "0px 24px",
                    }}
                />
            </div>

            <div
                style={{
                    position:"relative",
                    minHeight:"300px"
                }}
            >
                <div
                    style={{
                        display:`${isLoading ? "flex" : "none"}`,
                        justifyContent:"center",
                        alignItems:"start",
                        height: "100vh",
                        width: "100%",
                        position: "absolute",
                        backgroundColor: "rgba(255, 255, 255, 1)",
                        zIndex: "100"
                    }}
                    >
                    <dotlottie-player src="https://lottie.host/146b6344-4522-4fb6-801b-ad69e405c7da/SOi8Hfc8r6.json" mode="normal" background="transparent" speed="1" style={{width: "300px", height: "300px", display: "block"}} direction="1" loop autoplay></dotlottie-player>
                </div>
                {/* {articoli.map((articolo) => (
                    <OrderCell key={articolo.CodArticolo} articolo={articolo} misure={unitaMisure} setCarrello={setCarrello} quantity={getArticleQuantity(articolo)} misura={getArticleMisura(articolo)}/>
                ))} */}
                {articoli.length === 0 ? (
                    <p
                        style={{
                            fontFamily: "Regular",
                            fontSize:"16px",
                            color: "#808080",
                            display: "inline-block",
                            width: "100%",
                            marginBottom: "0px",
                            padding: "0px 24px",
                            textAlign: "center",
                            marginTop: "64px"
                        }}
                    >
                        Non ci sono prodotti disponibili nella categoria selezionata.
                    </p>   
                ) : (
                    articoli.map((articolo) => (
                        <OrderCell key={articolo.CodArticolo} articolo={articolo} misure={unitaMisure} setCarrello={setCarrello} quantity={getArticleQuantity(articolo)} misura={getArticleMisura(articolo)}/>
                    ))
                )}
            </div>
            <div 
                style={{
                    marginBottom:"150px",
                    padding: "0px 24px",
                }}
            />
            
            
            <div 
                className="footer"
                style={{
                    position: "fixed",
                    bottom: 0,
                    left:0,
                    height: "75px",
                    borderRadius: "24px 24px 0px 0px",
                    borderTop: "2px solid #F0F0F0",
                    boxShadow: "0px -5px 25px 0px rgba(0, 0, 0, 0.10)",
                    width: "100%",
                    backgroundColor:"#FFFFFF"
                }}
            >
                <div
                    className="row"
                    style={{
                        marginTop: "12px",
                        padding: "0px 24px"
                    }}
                >
                    <div
                        className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-4"
                    >
                        <p
                            style={{
                                fontFamily: "SemiBold",
                                fontSize:"10px",
                                color: "#1C1C1A",
                                display: "inline-block",
                                width: "100%",
                                textAlign: "center !important",
                                marginBottom: "0px"
                            }}
                        >
                            TOT ORDINE:
                        </p>
                        <p
                            style={{
                                fontFamily: "Regular",
                                fontSize:"14px",
                                color: "#808080",
                                display: "inline-block",
                                width: "100%",
                                textAlign: "center !important",
                                marginBottom: "0px"
                            }}
                        >
                            {articoliSelezionati.length} articoli
                        </p>
                    </div>
                    <div
                        className="col-lg-8 col-md-8 col-sm-8 col-xs-8 col-8"
                        style={{
                            textAlign: "end"
                        }}
                    >
                        <button
                            className={articoliSelezionati.length > 0 ? "btn-green" : "btn-grey"}
                            onClick={ordina}
                        >
                            Ordina ora
                        </button>
                    </div>

                </div>
                
            </div>

        </div>
    )
}
function CategoryCell(props){

    function setCategory(){
        props.setCategory(props.codCategory);
    }

    return (
        <div
            onClick={setCategory}
            style={{
                width: "70px",
                marginRight:"12px",
                textAlign:"center",
                cursor: "pointer"
            }}
        >
            <div
                style={{
                    backgroundColor:`${props.isSelected ? (props.isCatalogo ? "#1C1C1A" : "#8B1B1A") : "#F0F0F0"}`,
                    borderRadius: "16px",
                    height: "70px",
                    width: "70px",
                    display:"flex",
                    justifyContent:"center",
                    alignItems:"center"
                }}
            >
                <img
                    src={props.image}
                    alt=''
                    style={{
                        height: "42px",
                        width:"42px",
                        display: "inline-block"
                    }}
                />
            </div>
            <label
                style={{
                    fontFamily: "SemiBold",
                    fontSize:"10px",
                    color: "#1C1C1A",
                    marginTop:"4px",
                    width:"70px"
                }}
            >
                {props.categoria}
            </label>
        </div>
    )
}

export default CategoryCell;
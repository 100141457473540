import React from "react";
import { useState } from "react";
import  DeleteIcon from "@material-ui/icons/Delete";

export function CarrelloCell(props) {

    const articolo = props.articolo;

    function showModal(){
        props.showModal(articolo);
    }

    return (
        <div>
            <div
                className="row"
                style={{
                    padding: "0px 24px",
                }}
            >
                <div
                    className="col-lg-8 col-md-8 col-sm-8 col-xs-8 col-8"
                    style={{
                        marginTop:"12px",
                        textAlign:"left"
                    }}
                >
                    <p
                        style={{
                            fontFamily: "SemiBold",
                            fontSize:"18px",
                            color: "#1C1C1A",
                            display: "inline-block",
                            width: "100%",
                            textAlign: "left !important",
                            marginBottom: "0px"
                        }}
                    >

                        {articolo.DescrizioneArticolo}
                    </p>
                    <p
                        style={{
                            fontFamily: "Medium",
                            fontSize:"14px",
                            color: "#808080",
                            display: "inline-block",
                            width: "100%",
                            textAlign: "left !important",
                            marginBottom: "0px"
                        }}
                    >
                        # {articolo.CodArticolo}
                    </p>
                </div>
                <div
                    className="col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3"
                    style={{
                        marginTop:"12px",
                        textAlign:"right"
                    }}
                >
                    <p
                        style={{
                            fontFamily: "Medium",
                            fontSize:"12px",
                            color: "#1C1C1A",
                            display: "inline-block",
                            width: "100%",
                            textAlign: "right !important",
                            marginBottom: "0px"
                        }}
                    >

                        {articolo?.misura?.label || articolo.UnitaMisuraLabel || articolo.UnitaMisura}
                    </p>
                    <p
                        style={{
                            fontFamily: "SemiBold",
                            fontSize:"16px",
                            color: "#1C1C1A",
                            display: "inline-block",
                            width: "100%",
                            textAlign: "right !important",
                            marginBottom: "0px"
                        }}
                    >

                        X {articolo?.qty || articolo.Quantita}
                    </p>
                </div>
                <div
                    className="col-lg-1 col-md-1 col-sm-1 col-xs-1 col-1"
                    onClick={showModal}
                    style={{
                        marginTop:"12px",
                        display: `${props.isRecap ? "none" : "flex"}`,
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    <DeleteIcon
                        style={{
                            width: "18px",
                            height: "18px",
                            color: "#D9D9D9"
                        }}
                    />
                </div>
            </div>
            <div
                style={{
                    backgroundColor: "#F0F0F0",
                    height: "2px",
                    marginTop: "12px"
                }}
            />
        </div>
    )
}

import React from "react";
import { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import  CloseIcon from "@material-ui/icons/Close";
import { LogoTop } from "..";
import axios from "axios";
import { SERVICE_URL } from "..";

export function SendRequestView(props, route, navigation) {

    const [note, setNote] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    const {token} = useParams();

    function goBack(){
        window.history.back();
    }

    function inviaRichiesta(){
        
        setIsLoading(true);

        let body = {
            "ds_note" : note
        }

        axios.post(SERVICE_URL + '/custom_order', body, {
            headers: {
                "Access-Control-Allow-Origin" : "*",
                "Access-Control-Allow-Methods": "POST, GET, PUT",
                "Access-Control-Allow-Headers" : "Content-type",
                "Content-type": "Application/json",
                'Authorization' : `Bearer ${token}`
            }
        }).then((response) => {
            if(response.data){
                
                setIsLoading(false);
                navigate("/" + token + "/requestSucccess");
            }
        }).catch((error) => {
            console.error(error);
            setIsLoading(false);
            
        })
    }

    function sleep (time) {
        return new Promise((resolve) => setTimeout(resolve, time));
    }

    const handleTextNote = event => {
        
        if(event && event.target && event.target.value){
            setNote(event.target.value);
        }
    }

    return (
        <div 
        className="container"
            style={{
                marginTop: "10px",
                position:"relative",
                height:"90vh",
                padding:"0px"
            }}
        >
            <div
                style={{
                    display:`${isLoading ? "flex" : "none"}`,
                    justifyContent:"center",
                    alignItems:"center",
                    height:"100%",
                    width: "100%",
                    position: "absolute",
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    zIndex: "100"
                }}
                >
                <dotlottie-player src="https://lottie.host/146b6344-4522-4fb6-801b-ad69e405c7da/SOi8Hfc8r6.json" mode="normal" background="transparent" speed="1" style={{width: "300px", height: "300px", display: "block"}} direction="1" loop autoplay></dotlottie-player>
            </div>
            <div
                className="row"
                style={{
                    padding: "0px 24px"
                }}
            >
                <div
                    className="col-lg-2 col-md-2 col-sm-2 col-xs-2 col-2"
                    
                    style={{
                        marginTop: "16px"
                    }}
                >
                    
                </div>
                <div
                    className="col-lg-8 col-md-8 col-sm-8 col-xs-8 col-8"
                    style={{
                        marginTop: "16px",
                        textAlign: "center"
                    }}
                >
                    <img
                        src={LogoTop}
                        alt=''
                        style={{
                            height: "60px",
                            display: "inline-block"
                        }}
                    />
                </div>
                <div
                    className="col-lg-2 col-md-2 col-sm-2 col-xs-2 col-2"
                    onClick={goBack}
                    style={{
                        marginTop: "16px",
                        
                    }}
                >
                    <CloseIcon 
                        style={{
                            width: "28px",
                            height: "28px",
                            color: "black" 
                        }}
                    />

                </div>

            </div>
            <div
                className="row"
                style={{
                    marginTop: "32px",
                    textAlign:"center",
                    padding: "0px 24px"
                }}
            >
                <p
                    style={{
                        fontFamily: "SemiBold",
                        fontSize:"20px",
                        color: "#1C1C1A",
                        display: "inline-block",
                        width: "100%",
                        textAlign: "center !important",
                        marginBottom: "0px"
                    }}
                >
                    Non hai trovato i prodotti che cercavi?
                </p>

            </div>
            <div
                className="row"
                style={{
                    marginTop: "12px",
                    textAlign: "center",
                    padding: "0px 24px"
                }}
            >
                <p
                    style={{
                        fontFamily: "Regular",
                        fontSize:"16px",
                        color: "#808080",
                        display: "inline-block",
                        width: "100%",
                        textAlign: "center !important",
                        marginBottom: "0px"
                    }}
                >
                    Scrivi qui di cosa hai bisogno, verrai contattato dal tuo agente di riferimento.
                </p>

            </div>
            <div
                className="row"
                style={{
                    marginTop: "50px",
                    padding: "0px 24px"
                }}
            >
                <textarea 
                    placeholder="Scrivi qui..."
                    rows={4}
                    onChange={handleTextNote}
                    style={{
                        fontFamily: "Regular",
                        fontSize:"14px",
                        color: "#1C1C1A",
                        borderRadius: "12px",
                        border: "2px solid #F0F0F0",
                        padding: "12px"
                    }}
                />
                 
            </div>

            <div 
                className="footer"
                style={{
                    position: "fixed",
                    bottom: 0,
                    left:0,
                    height: "75px",
                    borderRadius: "24px 24px 0px 0px",
                    borderTop: "2px solid #F0F0F0",
                    boxShadow: "0px -5px 25px 0px rgba(0, 0, 0, 0.10)",
                    width: "100%",
                    backgroundColor:"#FFFFFF"
                }}
            >
                <div
                    className="row"
                    style={{
                        marginTop: "12px",
                        padding: "0px 24px"
                    }}
                >
                    <div
                        className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12"
                        style={{
                            textAlign: "center"
                        }}
                    >
                        <button
                            className="btn-black"
                            style={{
                                fontSize: "18px",
                                height: "50px"
                            }}
                            onClick={inviaRichiesta}
                        >
                            Invia richiesta
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
import './App.css';
import './font/Poppins-Light.ttf';
import './font/Poppins-Regular.ttf';
import './font/Poppins-SemiBold.ttf';
import './font/Poppins-Bold.ttf';
import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { HomeView } from './view/HomeView';
import { ConfirmOrderView } from './view/ConfirmOrderView';
import { SuccessOrderView } from './view/SuccessOrderView';
import { SendRequestView } from './view/SendRequestView';
import { SuccessRequestView } from './view/SuccessRequestView';
import { ErrorView } from './view/ErrorView';
import '@dotlottie/player-component';
import { useParams } from 'react-router-dom';

import { SERVICE_URL } from '.';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import { RecapView } from './view/RecapView';


function App() {

  const [isLoading, setIsLoading] = useState(true);
  const [articoli, setArticoli] = useState([]);
  const [unitaMisure, setUnitaMisure] = useState([]);
  const [requestError, setRequestError] = useState(null);
  

  useEffect(() => {

    executeGetDataInfo();

  }, [])

  const executeGetDataInfo = () => {
    setIsLoading(true);
    
    const token = window.location.pathname.replace(/\//gi, '');

    axios.get(SERVICE_URL + '/start', {
      headers: {
        'Authorization' : 'Bearer ' + token
      }
    }).then((response) => {

        if(response.data){
          console.log(response.data)
          setArticoli(response.data.articoli);
          setUnitaMisure(response.data.unita_misura);
          setIsLoading(false);
          
        }

    }).catch((error) => {
      console.error(error);
      setIsLoading(false);
      setRequestError(error)
    });   
}

  return (
    <div>
      {isLoading ? (
        <div
          style={{
            display:"flex",
            justifyContent:"center",
            alignItems:"center",
            height:"100vh"
          }}
        >
          <dotlottie-player src="https://lottie.host/146b6344-4522-4fb6-801b-ad69e405c7da/SOi8Hfc8r6.json" mode="normal" background="transparent" speed="1" style={{width: "300px", height: "300px", display: "block"}} direction="1" loop autoplay></dotlottie-player>
        </div>
      ) : !requestError ?  (
          <div>
            <Router>
              <Routes>
                <Route exact path="/:token" element={<HomeView articoli={articoli} unitaMisure={unitaMisure} errore={requestError}/>}/>
                <Route exact path="/:token/carrello" element={<ConfirmOrderView />}/>
                <Route exact path="/:token/orderSuccess" element={<SuccessOrderView />}/>
                <Route exact path="/:token/sendRequest" element={<SendRequestView />}/>
                <Route exact path="/:token/requestSucccess" element={<SuccessRequestView />}/>
                <Route exact path="/:token/errore" element={<ErrorView errore={requestError}/>}/>
              </Routes>
            </Router>
          </div>
        ) : (
          <Router>
            <Routes>
              <Route exact path="/" element={<ErrorView errore={requestError}/>}/>
              <Route exact path="/order/:id" element={<RecapView />}/>
            </Routes>
          </Router>
          
        )}
    </div>
  );
}

export default App;

import React from "react";
import { useState, useEffect } from "react";
import { LogoTop } from "..";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export function SuccessRequestView() {

    const navigate = useNavigate();

    const {token} = useParams();

    function handleClose(){
        window.open("https://wa.me/393288813933?text=Menu", '_self');
    }

    return (
        <div>
            <div
            className="container"
                style={{
                    display:"flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                    padding: "0px 24px"
                }}
            >
                <img
                    src={LogoTop}
                    alt=''
                    style={{
                        height: "100px",
                        display: "inline-block"
                    }}
                />
                <p
                    style={{
                        fontFamily: "SemiBold",
                        textAlign: "center",
                        fontSize:"20px",
                        color: "#1C1C1A",
                        width: "160px",
                        marginTop: "52px"
                    }}
                >
                    La tua richiesta è stata inviata
                </p>
                <p
                    style={{
                        fontFamily: "Regular",
                        textAlign: "center",
                        fontSize:"16px",
                        color: "#808080",
                        marginTop: "20px"
                    }}
                >
                    
                </p>
            </div>
            <div
                onClick={handleClose}
                style={{
                    position: "fixed",
                    left: 0,
                    bottom: 46,
                    width: "100%",
                    textAlign: "center"
                }}
            >
                <p 
                    style={{
                        fontFamily: "SemiBold",
                        textAlign: "center",
                        fontSize:"18px",
                        color: "#808080",
                        textDecoration: "underline",
                        cursor: "pointer"
                    }}
                >
                    Chiudi
                </p>
            </div>
        </div>
    )
}
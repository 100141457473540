import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import "bootstrap/dist/css/bootstrap.min.css";

import LogoTop from "./images/pollo-monge.png";

import AnatraRedIcon from "./images/categorie/ic_anatra_red.svg";
import AnatraWhiteIcon from "./images/categorie/ic_anatra_white.svg";
import AnatraBlackIcon from "./images/categorie/ic_anatra_black.svg";

import CapponeRedIcon from "./images/categorie/ic_cappone_red.svg";
import CapponeWhiteIcon from "./images/categorie/ic_cappone_white.svg";
import CapponeBlackIcon from "./images/categorie/ic_cappone_black.svg";

import ConiglioRedIcon from "./images/categorie/ic_coniglio_red.svg";
import ConiglioWhiteIcon from "./images/categorie/ic_coniglio_white.svg";
import ConiglioBlackIcon from "./images/categorie/ic_coniglio_black.svg";

import FaraonaRedIcon from "./images/categorie/ic_faraona_red.svg";
import FaraonaWhiteIcon from "./images/categorie/ic_faraona_white.svg";
import FaraonaBlackIcon from "./images/categorie/ic_faraona_black.svg";

import NostraniRedIcon from "./images/categorie/ic_nostrani_red.svg";
import NostraniWhiteIcon from "./images/categorie/ic_nostrani_white.svg";
import NostraniBlackIcon from "./images/categorie/ic_nostrani_black.svg";

import OvinoRedIcon from "./images/categorie/ic_ovino_red.svg";
import OvinoWhiteIcon from "./images/categorie/ic_ovino_white.svg";
import OvinoBlackIcon from "./images/categorie/ic_ovino_black.svg";

import PolloRedIcon from "./images/categorie/ic_pollo_red.svg";
import PolloWhiteIcon from "./images/categorie/ic_pollo_white.svg";
import PolloBlackIcon from "./images/categorie/ic_pollo_black.svg";

import PolloGialloRedIcon from "./images/categorie/ic_pollogiallo_red.svg";
import PolloGialloWhiteIcon from "./images/categorie/ic_pollogiallo_white.svg";
import PolloGialloBlackIcon from "./images/categorie/ic_pollogiallo_black.svg";

import QuagliaRedIcon from "./images/categorie/ic_quaglia_red.svg";
import QuagliaWhiteIcon from "./images/categorie/ic_quaglia_white.svg";
import QuagliaBlackIcon from "./images/categorie/ic_quaglia_black.svg";

import SelvagginaRedIcon from "./images/categorie/ic_selvaggina_red.svg";
import SelvagginaWhiteIcon from "./images/categorie/ic_selvaggina_white.svg";
import SelvagginaBlackIcon from "./images/categorie/ic_selvaggina_black.svg";

import SuinoRedIcon from "./images/categorie/ic_suino_red.svg";
import SuinoWhiteIcon from "./images/categorie/ic_suino_white.svg";
import SuinoBlackIcon from "./images/categorie/ic_suino_black.svg";

import TacchinoRedIcon from "./images/categorie/ic_tacchino_red.svg";
import TacchinoWhiteIcon from "./images/categorie/ic_tacchino_white.svg";
import TacchinoBlackIcon from "./images/categorie/ic_tacchino_black.svg";

import TuttiRedIcon from "./images/categorie/ic_tutti_red.svg";
import TuttiWhiteIcon from "./images/categorie/ic_tutti_white.svg";
import TuttiBlackIcon from "./images/categorie/ic_tutti_black.svg";

import UovaRedIcon from "./images/categorie/ic_uova_red.svg";
import UovaWhiteIcon from "./images/categorie/ic_uova_white.svg";
import UovaBlackIcon from "./images/categorie/ic_uova_black.svg";

import VarieRedIcon from "./images/categorie/ic_varie_red.svg";
import VarieWhiteIcon from "./images/categorie/ic_varie_white.svg";
import VarieBlackIcon from "./images/categorie/ic_varie_black.svg";



export {LogoTop};


export {AnatraRedIcon};
export {AnatraWhiteIcon};
export {AnatraBlackIcon};

export {CapponeRedIcon};
export {CapponeWhiteIcon};
export {CapponeBlackIcon};

export {ConiglioRedIcon};
export {ConiglioWhiteIcon};
export {ConiglioBlackIcon};

export {FaraonaRedIcon};
export {FaraonaWhiteIcon};
export {FaraonaBlackIcon};

export {NostraniRedIcon};
export {NostraniWhiteIcon};
export {NostraniBlackIcon};

export {OvinoRedIcon};
export {OvinoWhiteIcon};
export {OvinoBlackIcon};

export {PolloRedIcon};
export {PolloWhiteIcon};
export {PolloBlackIcon};

export {PolloGialloRedIcon};
export {PolloGialloWhiteIcon};
export {PolloGialloBlackIcon};

export {QuagliaRedIcon};
export {QuagliaWhiteIcon};
export {QuagliaBlackIcon};

export {SelvagginaRedIcon};
export {SelvagginaWhiteIcon};
export {SelvagginaBlackIcon};

export {SuinoRedIcon};
export {SuinoWhiteIcon};
export {SuinoBlackIcon};

export {TacchinoRedIcon};
export {TacchinoWhiteIcon};
export {TacchinoBlackIcon};

export {TuttiRedIcon};
export {TuttiWhiteIcon};
export {TuttiBlackIcon};

export {UovaRedIcon};
export {UovaWhiteIcon};
export {UovaBlackIcon};

export {VarieRedIcon};
export {VarieWhiteIcon};
export {VarieBlackIcon};

export const SERVICE_URL = "https://pollomonge.etihub.it/api";


const el = document.getElementById('root');

const root = ReactDOM.createRoot(el);

root.render(<App/>);

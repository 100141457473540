import React from "react";
import { useState, useEffect } from "react";
import { LogoTop } from "..";
import  ArrowBack from "@material-ui/icons/ArrowBack";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CarrelloCell } from "../components/CarrelloCell";
import axios from "axios";

import "react-datepicker/dist/react-datepicker.css";

import { registerLocale, setDefaultLocale } from  "react-datepicker";
import it from 'date-fns/locale/it';

registerLocale('it', it);
setDefaultLocale("it");

export function RecapView() {


    const navigate = useNavigate();

    const [carrello, setCarrello] = useState([]);
    const [user, setUser] = useState({});
    const [dtConsegna, setDtConsegna] = useState("");
    const [note, setNote] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const {id} = useParams();

    useEffect(() => {
        window.scrollTo(0, 0);
        executeGetRecapOrder();
    }, []);

    const executeGetRecapOrder = () => {
        setIsLoading(true);

        if(!id) {
            setIsLoading(false);
            navigate("/");
            return;
        }

        axios.get('https://chatbot.pingueen.it/ext/bot/monge/order/' + id).then((response) => {

            if(response.data){
                console.log(response.data)
                setCarrello(response.data?.order?.metadata?.order?.Dettaglio);
                setNote(response.data?.order?.metadata?.order?.Note);
                setDtConsegna(response.data?.order?.metadata?.order?.DataConsegnaRichiesta);
                setUser(response.data?.order?.metadata?.user);
                setIsLoading(false);

            }

        }).catch((error) => {
          console.error(error);
          setIsLoading(false);

        });
    }

    return (
        <div
        className="container"
            style={{
                marginTop: "10px",
                position: "relative",
                padding:"0px"
            }}
        >
            <div
                style={{
                    display:`${isLoading ? "flex" : "none"}`,
                    justifyContent:"center",
                    alignItems:"center",
                    height:"100%",
                    width: "100%",
                    position: "absolute",
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    zIndex: "100"
                }}
                >
                <dotlottie-player src="https://lottie.host/146b6344-4522-4fb6-801b-ad69e405c7da/SOi8Hfc8r6.json" mode="normal" background="transparent" speed="1" style={{width: "300px", height: "300px", display: "block"}} direction="1" loop autoplay></dotlottie-player>
            </div>

            <div
                className="row"
                style={{
                    padding: "0px 24px",
                }}
            >
                <div
                    className="col-lg-2 col-md-2 col-sm-2 col-xs-2 col-2"

                    style={{
                        marginTop: "16px"
                    }}
                >

                </div>
                <div
                    className="col-lg-8 col-md-8 col-sm-8 col-xs-8 col-8"
                    style={{
                        marginTop: "16px",
                        textAlign: "center"
                    }}
                >
                    <img
                        src={LogoTop}
                        alt=''
                        style={{
                            height: "60px",
                            display: "inline-block"
                        }}
                    />
                </div>
                <div
                    className="col-lg-2 col-md-2 col-sm-2 col-xs-2 col-2"
                    style={{
                        marginTop: "16px",

                    }}
                >


                </div>

            </div>
            <div
                className="row"
                style={{
                    marginTop: "32px",
                    textAlign:"center",
                    padding: "0px 24px",
                }}
            >
                <p
                    style={{
                        fontFamily: "SemiBold",
                        fontSize:"20px",
                        color: "#1C1C1A",
                        display: "inline-block",
                        width: "100%",
                        textAlign: "center !important",
                        marginBottom: "0px"
                    }}
                >
                    Riepilogo ordine
                </p>

            </div>
            <div
                className="row"
                style={{
                    marginTop: "12px",
                    textAlign: "center",
                    padding: "0px 24px",
                }}
            >
                <p
                    style={{
                        fontFamily: "Regular",
                        fontSize:"16px",
                        color: "#808080",
                        display: "inline-block",
                        width: "100%",
                        textAlign: "center !important",
                        marginBottom: "0px"
                    }}
                >
                    Controlla il tuo ordine
                </p>

            </div>

            <div
                style={{
                    backgroundColor: "#F0F0F0",
                    height: "2px",
                    marginTop: "40px",
                    padding: "0px 24px",
                }}
            />


            {carrello.map((articolo) => (
                <CarrelloCell key={articolo.CodArticolo} articolo={articolo} isRecap={true}/>
            ))}

            {/* {dtConsegna && dtConsegna !== "" && <div
                    className="row"
                    style={{
                        marginTop: "40px",
                        padding: "0px 24px",
                    }}
                >
                    <p
                        style={{
                            fontFamily: "SemiBold",
                            fontSize:"18px",
                            color: "#1C1C1A",
                            display: "inline-block",
                            width: "100%",
                            textAlign: "left !important",
                            marginBottom: "0px"
                        }}
                    >

                        DATA RICHIESTA CONSEGNA
                    </p>
                    <p

                        style={{
                            fontFamily: "Regular",
                            fontSize:"14px",
                            marginTop: "8px",
                            color: "#808080",
                        }}
                    >
                        {dtConsegna}
                    </p>
                </div>
            } */}
            <div
                className="row"
                style={{
                    marginTop: "40px",
                    padding: "0px 24px",
                }}
            >
                <p
                    style={{
                        fontFamily: "SemiBold",
                        fontSize:"16px",
                        color: "#1C1C1A",
                        display: "inline-block",
                        width: "100%",
                        textAlign: "center !important",
                        marginBottom: "0px"
                    }}
                >
                    Note:
                </p>

            </div>
            <div
                className="row"
                style={{
                    marginTop: "8px",
                    padding: "0px 24px",
                }}
            >
                <p

                    style={{
                        fontFamily: "Regular",
                        fontSize:"14px",

                        color: "#808080",
                    }}
                >
                    {note}
                </p>

            </div>
            <div
                className="row"
                style={{
                    marginTop: "40px",
                    padding: "0px 24px",
                }}
            >
                <p
                    style={{
                        fontFamily: "SemiBold",
                        fontSize:"18px",
                        color: "#1C1C1A",
                        display: "inline-block",
                        width: "100%",
                        textAlign: "left !important",
                        marginBottom: "0px"
                    }}
                >

                    DATI FATTURAZIONE
                </p>

            </div>
            <div
                className="row"
                style={{
                    marginTop: "16px",
                    padding: "0px 24px",
                }}
            >
                <p
                    style={{
                        fontFamily: "SemiBold",
                        fontSize: "16px",
                        color: "#1C1C1A",
                        display: "inline-block",
                        width: "100%",
                        textAlign: "center !important",
                        marginBottom: "0px"
                    }}
                >
                    Ragione sociale:
                </p>

                <p

                    style={{
                        fontFamily: "Regular",
                        fontSize: "14px",

                        color: "#808080",
                    }}
                >
                    {user.ragionesociale}
                </p>

                <p
                    style={{
                        fontFamily: "SemiBold",
                        fontSize: "16px",
                        color: "#1C1C1A",
                        display: "inline-block",
                        width: "100%",
                        textAlign: "center !important",
                        marginBottom: "0px"
                    }}
                >
                    Destinazione:
                </p>

                <p

                    style={{
                        fontFamily: "Regular",
                        fontSize: "14px",

                        color: "#808080",
                    }}
                >
                    {user.destinazione}
                </p>

                <p
                    style={{
                        fontFamily: "SemiBold",
                        fontSize: "16px",
                        color: "#1C1C1A",
                        display: "inline-block",
                        width: "100%",
                        textAlign: "center !important",
                        marginBottom: "0px"
                    }}
                >
                    Numero telefono:
                </p>

                <p

                    style={{
                        fontFamily: "Regular",
                        fontSize: "14px",

                        color: "#808080",
                    }}
                >
                    {user.clientphone}
                </p>


            </div>
            <div
                style={{
                    backgroundColor: "white",
                    height: "100px"
                }}
            />

        </div>
    )
}
